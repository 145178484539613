import {CheckoutNavigationService, ModalType} from '@wix/wixstores-client-storefront-sdk';
import {getAdditionalFeesPrice, getCatalogAppIds, getNumberOfAdditionalFees} from './bi.utils';
import {EstimatedTotalsModel} from '../models/EstimatedTotals.model';
import {CartModel} from '../models/Cart.model';

export const openNoOnlinePaymentsModal = async (
  checkoutNavigationService: CheckoutNavigationService,
  isEditorX: boolean,
  cartModel: CartModel,
  estimatedTotals: EstimatedTotalsModel
): Promise<void> => {
  const destination = cartModel.contactInfo.address;
  const catalogAppId = getCatalogAppIds(cartModel);
  const additionalFeesPrice = getAdditionalFeesPrice(estimatedTotals);
  const additionalFeesNumber = getNumberOfAdditionalFees(estimatedTotals);
  await checkoutNavigationService.openLegacyCartModal(
    ModalType.NoOnlinePayments,
    {destination, catalogAppId, additionalFeesPrice, additionalFeesNumber},
    isEditorX
  );
};
